//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cover: {
      type: Object,
      default: () => {},
    },
    goodsTitle: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    typeTags: {
      type: Array,
      default: () => [],
    },
    expiryPeriod: {
      type: Number,
      default: null,
    },
    goodsIntro: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: null,
    },
    employeesPrice: {
      type: Number,
      default: null,
    },
    lastMonthDeliveryCount: {
      type: Number,
      default: null,
    },
    count: {
      type: Number,
      default: 0,
    },
    countMax: {
      type: Number,
      default: 10000,
    },
    countDisabled: {
      type: Boolean,
      default: false,
    },
    stockTips: {
      type: String,
      default: '',
    },
    isStockout: {
      type: Boolean,
      default: false,
    },
    deliveryTypes: {
      type: Array,
      default: () => [],
    },
    deliveryInfo: {
      type: Object,
      default: undefined,
    },
    limit: {
      type: Number,
      default: undefined,
    },
    optionButton: {
      type: Object,
      default: () => {},
    },
    displayPerDeliveryCount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countValue: this.count,
    }
  },

  computed: {
    listLabel() {
      const list = []

      if (this.price !== null) {
        list.push({
          name: '販売価格',
          text: `${this.price}円`,
        })
      }

      if (this.employeesPrice !== null) {
        list.push({
          name: '従業員販売価格',
          text: `${this.employeesPrice}円`,
        })
      }

      if (this.lastMonthDeliveryCount !== null) {
        list.push({
          name: '前月のリクエスト数',
          text: `${this.lastMonthDeliveryCount}個`,
        })
      }

      list.push({
        name: `ご希望お届け数${this.limit ? `(${this.limit}個まで)` : ''}`,
      })

      return list
    },
    deliveryPerCount() {
      let total = 0

      if (this.countValue === 0 || !this.deliveryInfo) {
        return `0個`
      }

      this.deliveryTypes.map((key) => {
        total += this.deliveryInfo[`${key}Count`]

        return key
      })

      if (total === 0) {
        return `0個`
      }

      const result = this.countValue / total
      const floorResult = Math.floor(result)

      if (floorResult === result) {
        return `${result}個`
      }

      return `${floorResult}~${floorResult + 1}個`
    },
    countMaxLimit() {
      return this.limit || this.countMax
    },
  },

  watch: {
    count(newValue) {
      if (newValue !== this.countValue) {
        this.countValue = newValue
      }
    },
  },

  methods: {
    inputCount(input) {
      if (!Number.isInteger(Number(input.data))) {
        input.target.value = this.countValue
      }
    },
    selectCount(value) {
      if (isNaN(value)) {
        value = 0

        this.countValue = value
      }

      this.$emit('count', value)
    },
  },
}
