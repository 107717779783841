//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgAddonPage',

  layout: 'page',

  data() {
    const { floor, expectDeliveryDate } = this.$route.query

    return {
      listLoading: false,
      submitLoading: false,
      floor: { id: floor },
      floors: null,
      date: addMonths(new Date(), 1),
      pickerOptions: {},
      courseList: [],
      addonTips: '',
      addonWarnTips: '',
      expectDeliveryDate:
        expectDeliveryDate || formatDate(new Date(), 'yyyy-MM-dd'),
      deliveryDateList: [],
      confirm: {},
    }
  },

  computed: {
    countList() {
      return this.courseList.map((item) => {
        return {
          name: item.catName,
          text: `${item.count ?? 0} セット`,
          id: item.catID,
          count: item.count,
        }
      })
    },
    courseID() {
      return this.countList.map((item) => item.id).join(',')
    },
    courseCount() {
      return this.countList.map((item) => item.count).join(',')
    },
    selectedCourse() {
      const { courseID, courseCount } = this.$route.query

      if (!courseID || !courseCount) {
        return
      }

      const map = {}
      const courseCountAry = courseCount.split(',')

      courseID.split(',').map((item, index) => {
        map[item] = Number(courseCountAry[index])

        return item
      })

      return map
    },
    allowSubmit() {
      if (
        this.countList.every((item) => item.count < 1) ||
        !this.expectDeliveryDate
      ) {
        return false
      }

      return true
    },
    introTips() {
      return `定期便以外に商品をご注文されたい場合、ご注文いただけます。<br />${this.addonTips}`
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true

      const res = await this.$axios.$get('/frozen/courses', {
        params: {
          cid: this.floor.id,
          isAddon: true,
          deliveryDate: this.expectDeliveryDate,
        },
      })

      this.listLoading = false

      if (!res.data) {
        return
      }

      const {
        floor,
        courseList,
        addonTips,
        addonWarnTips,
        deliveryDateList,
        confirm,
      } = res.data

      this.floor = floor
      this.addonTips = addonTips
      this.addonWarnTips = addonWarnTips
      this.deliveryDateList = deliveryDateList
      this.confirm = confirm

      if (deliveryDateList?.length > 0) {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return !deliveryDateList.includes(formatDate(time, 'yyyy-MM-dd'))
        })

        if (!deliveryDateList.includes(this.expectDeliveryDate)) {
          this.expectDeliveryDate = deliveryDateList[0]
        }
      } else {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return true
        })

        this.expectDeliveryDate = null
      }

      this.courseList = courseList.map((item, index) => {
        item.goodsTitle = item.catName
        item.cover = item.cover?.url
        item.maxSetCount = item.maxSetCount ?? undefined

        if (this.selectedCourse && this.selectedCourse[item.catID]) {
          item.count = this.selectedCourse[item.catID]
        }

        return item
      })
    },
    floorChange() {
      this.fetchOptions()
    },
    async submit(item) {
      try {
        if (this.confirm.enable && item.count >= this.confirm.threshold) {
          await this.$confirm(
            this.confirm.message,
            '商品の選択に進む 確認画面',
            {
              cancelButtonText: 'キャンセル',
              confirmButtonText: '確認',
            }
          )
        }
      } catch (error) {
        return
      }

      this.$router.push({
        path: '/odg/request-basic',
        query: {
          courseID: item.catID,
          courseCount: item.count,
          isAddon: true,
          expectDeliveryDate: this.expectDeliveryDate,
          floor: this.floor.id,
        },
      })
    },
  },
}
