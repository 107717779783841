//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomePage',

  layout: 'page',

  async asyncData({ $axios }) {
    const res = await $axios.$get('/home')

    if (!res.data) {
      return {}
    }

    const { newsList, pushList, banners } = res.data

    const adList =
      banners?.right?.map((item, index) => ({
        id: index,
        imgUrl: item.img_url,
        link: item.destination_url,
        target: item.destination_url?.includes('http') ? '_blank' : '_self',
        customLink: null,
      })) || []

    // config sso ad links
    const ssoAdLinks = ['/lp/help']
    const ssoLinkTo = async () => {
      const res = await $axios.$post('/zendesk/sso')

      if (res.url) {
        location.href = res.url
      }
    }

    adList.forEach((ad) => {
      if (ssoAdLinks.includes(ad.link)) {
        ad.customLink = (e) => {
          e.preventDefault()

          ssoLinkTo()
        }
      }
    })

    const adTopList =
      banners?.top?.map((item, index) => ({
        id: index,
        imgUrl: item.img_url,
        link: item.destination_url,
        target: item.destination_url?.includes('http') ? '_blank' : '_self',
      })) || []

    return {
      newsList,
      pushList,
      adList,
      adTopList,
    }
  },

  data() {
    return {
      newsList: [],
      pushList: [],
      adList: [],
      adTopList: [],
    }
  },

  computed: {
    _newsList() {
      return this.newsList.map((item) => {
        if (item.id) {
          item.link = `/news/detail?id=${item.id}`
        }

        return item
      })
    },
  },
}
