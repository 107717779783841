import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c9df8430&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ListAd: require('/home/runner/work/myody_front/myody_front/components/list/ListAd.vue').default,Link: require('/home/runner/work/myody_front/myody_front/components/link/Link.vue').default,ListNews: require('/home/runner/work/myody_front/myody_front/components/list/ListNews.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default})
