//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgHistoryPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      typeList: [],
      list: [],
      listLoading: false,
      floor: { id: query.floor },
      type: 'all',
      date: new Date(),
      dateMax: new Date(),
      tips: '',
    }
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList() {
      this.listLoading = true

      const res = await this.$axios.$get('/frozen/history', {
        params: {
          type: this.type,
          year: formatDate(this.date, 'yyyy'),
          cid: this.floor.id,
        },
      })

      this.listLoading = false

      if (!res.data) {
        return
      }

      const { historyList, floor, typeList, tips } = res.data

      const linkMap = {
        monthly: '/odg/history/product',
        addon: '/odg/history/product',
        cutlery: '/odg/history/cutlery',
      }

      this.floor = floor

      const showTypes = typeList
        ?.filter((item) => item.displayInAll)
        .map((item) => item.type)

      let renderList = historyList

      renderList.map((item) => {
        item.linkTo = `${linkMap[item.type]}/${item.historyID}`

        return item
      })

      if (this.type === 'all') {
        renderList = renderList.filter((item) => showTypes.includes(item.type))
        this.tips = tips
      } else {
        this.tips = typeList.find((item) => item.type === this.type)?.tips
      }

      this.list = renderList

      if (typeList.length > 1 || typeList[0]?.displayInAll === false) {
        this.typeList = typeList
      }
    },
  },
}
