//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate, currency } from '@/plugins/filters'

export default {
  name: 'OdgCutleryPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      submitLoading: false,
      floor: { id: query.floor },
      pickerOptions: {},
      headerTips: '',
      expectDeliveryDate: formatDate(new Date(), 'yyyy-MM-dd'),
      deliveryDateList: [],
      equipmentList: [],
      shippingFee: 0,
      taxRate: 0,
      countTotal: 0,
      priceTotal: 0,
      shippingFeeTotal: 0,
      taxRateTotal: 0,
      amountTotal: 0,
    }
  },

  computed: {
    countList() {
      return this.equipmentList.map((item) => {
        const price = item.price * item.count

        return {
          name: item.equipmentName,
          // TODO: need update style for design (3 colunms), such as add extra to ListLabel
          text: `${item.count} セット ${currency(price)}`,
          count: item.count,
          price,
        }
      })
    },
    countSummary() {
      return `${this.countTotal} セット ${currency(this.priceTotal)}`
    },
    allowSubmit() {
      if (
        this.countList.every((item) => item.count < 1) ||
        !this.expectDeliveryDate
      ) {
        return false
      }

      return true
    },
  },

  watch: {
    countList(newValue) {
      let countTotal = 0
      let priceTotal = 0

      newValue.map((item) => {
        countTotal += item.count
        priceTotal += item.price

        return item
      })

      this.countTotal = countTotal
      this.priceTotal = priceTotal
      this.shippingFeeTotal =
        Math.floor((countTotal - 1) / 5 + 1) * this.shippingFee
      this.taxRateTotal =
        ((priceTotal + this.shippingFeeTotal) * this.taxRate) / 100

      this.amountTotal =
        this.priceTotal + this.shippingFeeTotal + this.taxRateTotal
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true

      const res = await this.$axios.$get('/equipments/options', {
        params: {
          deliveryDate: this.expectDeliveryDate,
          cid: this.floor.id,
        },
      })

      this.listLoading = false

      if (!res.data) {
        return
      }

      const {
        equipmentList,
        tips,
        floor,
        deliveryDateList,
        taxRate,
        shippingFee,
      } = res.data

      this.floor = floor
      this.deliveryDateList = deliveryDateList

      if (deliveryDateList?.length > 0) {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return !deliveryDateList.includes(formatDate(time, 'yyyy-MM-dd'))
        })

        if (!deliveryDateList.includes(this.expectDeliveryDate)) {
          this.expectDeliveryDate = deliveryDateList[0]
        }
      } else {
        this.$set(this.pickerOptions, 'disabledDate', (time) => {
          return true
        })

        this.expectDeliveryDate = null
      }

      this.equipmentList = equipmentList.map((item) => {
        item.goodsTitle = item.equipmentName
        item.cover = item.cover?.url
        item.countMax = item.limit

        return item
      })

      this.headerTips = tips
      this.taxRate = taxRate
      this.shippingFee = shippingFee
    },
    floorChange() {
      this.expectDeliveryDate = formatDate(new Date(), 'yyyy-MM-dd')

      this.fetchOptions()
    },
    async submit() {
      try {
        await this.$confirm('選択した内容で注文しますか？', '備品 注文確認')
      } catch (error) {
        return
      }

      const form = {
        cid: this.floor.id,
        deliveryDate: this.expectDeliveryDate,
        priceTotal: this.priceTotal,
        equipmentList: this.equipmentList.map((item) => {
          return {
            equipmentID: item.equipmentID,
            price: item.price,
            count: item.count,
          }
        }),
      }

      this.submitLoading = true

      const res = await this.$axios.post('/equipments/requests', form)

      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      this.$router.push('/odg/cutlery-complete')
    },
  },
}
