//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate, currency } from '@/plugins/filters'
import Report from '@/report/sentryOdgList'

export default {
  name: 'OdgRequestBasicPage',

  mixins: [Report],

  layout: 'page',

  data() {
    const { expectDeliveryDate, isAddon, courseID, courseCount, floor } =
      this.$route.query

    return {
      isAddon,
      courseID,
      courseCount,
      step: 1,
      listLoading: false,
      submitLoading: false,
      floor: { id: floor },
      date: addMonths(new Date(), 1),
      deliveryDate: expectDeliveryDate,
      options: [],
      goodsCat: null,
      catList: [],
      catListMap: [],
      usageFee: 0,
      priceTotal: 0,
      employeesPriceTotal: 0,
      billingAmount: 0,
      feedbackNeeds: '',
      sorts: [
        {
          name: 'price',
          label: '販売価格',
          type: 'default',
        },
        {
          name: 'employeesPrice',
          label: '従業員販売価格',
          type: 'default',
        },
        {
          name: 'count',
          label: 'ご希望お届け数',
          type: 'default',
        },
      ],
      sortActive: '',
      sortType: 'default',
      tips: '',
      priceFilter: [],
      banners: [],
      lastRequestDatetimeThisMonth: null,
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },

  computed: {
    typeTitle() {
      return this.isAddon ? '追加注文' : '定期リクエスト'
    },
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    goodsList() {
      return this.catListMap[this.goodsCat]
    },
    goodsFilterList() {
      if (!this.goodsList) {
        return []
      }

      if (this.priceFilter.length < 1) {
        return this.goodsList
      }

      return this.goodsList.filter((item) =>
        this.priceFilter.includes(item.price)
      )
    },
    goodsSortList() {
      if (!this.goodsFilterList) {
        return []
      }

      const newList = this.goodsFilterList.slice()

      if (!this.sortActive) {
        return newList
      }

      return this.handleSort(newList, this.sortActive, this.sortType)
    },
    goodsPriceList() {
      if (!this.goodsList) {
        return []
      }
      return this.goodsList
        .reduce((acc, cur) => {
          if (!acc.includes(cur.price)) {
            acc.push(cur.price)
          }

          return acc
        }, [])
        .sort((a, b) => a - b)
    },
    countList() {
      return this.catList.map((item) => {
        let count = 0
        let price = 0
        let employeesPrice = 0

        for (let i = 0; i < item.goodsList.length; i++) {
          const goods = item.goodsList[i]

          count += goods.count
          price += goods.price * goods.count
          employeesPrice += goods.employeesPrice * goods.count * goods.includes
        }

        const perSetMax = item.perSetCount * item.count

        return {
          name: item.catName,
          count,
          countText: `${count} /${perSetMax} 個`,
          perSetMax,
          price,
          employeesPrice,
          priceText: currency(price),
          employeesPriceText: currency(employeesPrice),
        }
      })
    },
    allowSubmit() {
      if (this.countList.some((item) => item.perSetMax !== item.count)) {
        return false
      }

      return true
    },
  },

  watch: {
    countList(newValue) {
      let priceTotal = 0
      let employeesPriceTotal = 0

      for (let i = 0; i < newValue.length; i++) {
        const item = newValue[i]

        priceTotal += item.price
        employeesPriceTotal += item.employeesPrice
      }

      this.priceTotal = priceTotal
      this.employeesPriceTotal = employeesPriceTotal
      this.billingAmount = this.usageFee + priceTotal - employeesPriceTotal
    },
    goodsCat() {
      this.priceFilter = []
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true
      this.options = []

      const form = {
        cid: this.floor.id,
        courseID: this.courseID,
        courseCount: this.courseCount,
      }

      if (this.isAddon) {
        form.deliveryDate = this.deliveryDate
      } else {
        form.month = this.month
      }

      const apiUrl = this.isAddon
        ? '/frozen/addon/options'
        : '/frozen/monthly/options'

      const res = await this.$axios.$get(apiUrl, {
        params: form,
      })

      this.listLoading = false

      if (!res.data?.catList) {
        return
      }

      const {
        catList,
        deliveryDate,
        usageFee,
        displayStockRange,
        needs,
        tips,
        banners,
        lastRequestDatetimeThisMonth,
      } = res.data
      const options = []
      const catListMap = {}

      catList.map((item) => {
        options.push({
          id: item.catID,
          label: item.catName,
        })

        catListMap[item.catID] = item.goodsList.map((goods) => {
          const curStockRange = item.displayStockRange || displayStockRange

          if (!goods.count) {
            goods.count = 0
          }

          if (curStockRange) {
            if (goods.stock < curStockRange.start) {
              goods.stockTips = '在庫なし'
              goods.isStockout = true
            } else if (goods.stock < curStockRange.end) {
              goods.stockTips = `残り:${goods.stock}個`
            }
          }

          return goods
        })

        return item
      })

      this.catList = catList.slice()
      this.options = options
      this.catListMap = catListMap
      this.feedbackNeeds = needs
      this.tips = tips
      this.banners = banners.map((item) => {
        item.link = item.link.includes('http')
          ? item.link
          : `${window.location.search}${item.link}`

        return item
      })

      if (deliveryDate) {
        this.deliveryDate = deliveryDate
      }

      this.usageFee = usageFee

      this.lastRequestDatetimeThisMonth = lastRequestDatetimeThisMonth

      // set options first as default
      this.goodsCat = this.options[0]?.id

      this.$nextTick(() => {
        this.ReportList(res.data)
      })
    },
    goodsCountChange(item) {
      const index = this.catList.findIndex((cat) => cat.catID === this.goodsCat)
      const catItem = this.catList[index]
      const goodsIndex = catItem.goodsList.findIndex(
        (goods) => goods.id === item.id
      )

      catItem.goodsList[goodsIndex].count = item.count

      this.$set(this.catList, index, catItem)
    },
    doSort(item) {
      if (this.sortActive !== item.name) {
        if (this.sortActive) {
          const lastSort = this.sorts.find(
            (sort) => sort.name === this.sortActive
          )

          if (lastSort) {
            lastSort.type = 'default'
          }
        }

        this.sortActive = item.name

        item.type = 'desc'
      } else {
        switch (item.type) {
          case 'default':
            item.type = 'desc'
            break
          case 'desc':
            item.type = 'asc'
            break
          case 'asc':
            item.type = 'default'
            this.sortActive = ''
            break
          default:
            break
        }
      }

      this.sortType = item.type
    },
    handleSort(data, key, type) {
      data.sort((a, b) => a[key] - b[key])

      if (type === 'desc') {
        data.reverse()
      }

      return data
    },
    resetAll() {
      this.catList = this.catList.map((item) => {
        item.goodsList = item.goodsList.map((goods) => {
          goods.count = 0

          return goods
        })

        return item
      })
    },
    navInitStep() {
      const path = this.isAddon ? '/odg/addon' : '/odg/request'

      this.$router.push({
        path,
        query: this.$route.query,
      })
    },
    async submit() {
      if (this.isAddon) {
        try {
          await this.$confirm(
            '選択した内容で注文しますか？',
            '追加注文　注文確認'
          )
        } catch (error) {
          return
        }
      }

      let form = {}
      let apiUrl

      if (this.isAddon) {
        form = {
          deliveryDate: this.deliveryDate,
          cid: this.floor.id,
          courses: this.catList.map((cat) => {
            return {
              catID: cat.catID,
              perSetCount: cat.perSetCount,
              count: cat.count,
              goodsList: cat.goodsList.map((goods) => {
                return {
                  id: goods.id,
                  price: goods.price,
                  count: goods.count,
                }
              }),
            }
          }),
          priceTotal: this.priceTotal,
          billingAmount: this.billingAmount,
        }

        apiUrl = '/frozen/addon/request'
      } else {
        form = {
          month: this.month,
          cid: this.floor.id,
          courses: this.catList.map((cat) => {
            return {
              catID: cat.catID,
              perSetCount: cat.perSetCount,
              count: cat.count,
              goodsList: cat.goodsList.map((goods) => {
                return {
                  id: goods.id,
                  price: goods.price,
                  count: goods.count,
                }
              }),
            }
          }),
          priceTotal: this.priceTotal,
          billingAmount: this.billingAmount,
          needs: this.feedbackNeeds,
        }

        apiUrl = '/frozen/monthly/request'
      }

      this.submitLoading = true

      const res = await this.$axios.post(apiUrl, form)

      this.ReportSubmit(apiUrl, form, res)

      this.submitLoading = false

      if (res.status !== 200) {
        return
      }

      this.$toast({
        message: '保存しました',
      })

      const query = {}

      if (this.isAddon) {
        query.isAddon = true
      }

      this.$router.push({
        path: '/odg/request-complete',
        query,
      })
    },
  },
}
