import { render, staticRenderFns } from "./request.vue?vue&type=template&id=37122cdc&"
import script from "./request.vue?vue&type=script&lang=js&"
export * from "./request.vue?vue&type=script&lang=js&"
import style0 from "./request.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoodsOdgList: require('/home/runner/work/myody_front/myody_front/components/goods/GoodsOdgList.vue').default,Card: require('/home/runner/work/myody_front/myody_front/components/card/Card.vue').default,Col: require('/home/runner/work/myody_front/myody_front/components/col/Col.vue').default,Row: require('/home/runner/work/myody_front/myody_front/components/row/Row.vue').default})
