//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addMonths } from 'date-fns'
import { formatDate } from '@/plugins/filters'

export default {
  name: 'OdgRequestPage',

  layout: 'page',

  data() {
    const { query } = this.$route

    return {
      listLoading: false,
      submitLoading: false,
      floor: { id: query.floor },
      date: addMonths(new Date(), 1),
      startDate: null,
      endDate: null,
      deliveryDate: null,
      courseList: [],
      addonTips: '',
      addonWarnTips: '',
      isAdmin: null,
      canRequest: null,
      warnTips: '',
      sellCountBudget: null,
      tips: '',
      confirm: {},
    }
  },

  head() {
    return {
      bodyAttrs: {
        class: this.$options.name,
      },
    }
  },

  computed: {
    month() {
      return formatDate(this.date, 'yyyy-MM')
    },
    countList() {
      return this.courseList.map((item) => {
        return {
          name: item.catName,
          text: `${item.count} セット`,
          id: item.catID,
          count: item.count,
        }
      })
    },
    courseID() {
      return this.countList.map((item) => item.id).join(',')
    },
    courseCount() {
      return this.countList.map((item) => item.count).join(',')
    },
    selectedCourse() {
      const { courseID, courseCount } = this.$route.query

      if (!courseID || !courseCount) {
        return
      }

      const map = {}
      const courseCountAry = courseCount.split(',')

      courseID.split(',').map((item, index) => {
        map[item] = Number(courseCountAry[index])

        return item
      })

      return map
    },
    allowSubmit() {
      if (!this.isAdmin && !this.canRequest) {
        return false
      }

      if (this.countList.every((item) => item.count < 1)) {
        return false
      }

      return true
    },
  },

  mounted() {
    this.fetchOptions()
  },

  methods: {
    async fetchOptions() {
      this.listLoading = true

      const res = await this.$axios.$get('/frozen/courses', {
        params: {
          month: this.month,
          cid: this.floor.id,
        },
      })

      this.listLoading = false

      const {
        floor,
        startDate,
        endDate,
        deliveryDate,
        courseList,
        addonTips,
        addonWarnTips,
        isAdmin,
        canRequest,
        warnTips,
        sellCountBudget,
        tips,
        confirm,
      } = res.data

      this.startDate = startDate
      this.endDate = endDate
      this.deliveryDate = deliveryDate
      this.addonTips = addonTips
      this.addonWarnTips = addonWarnTips
      this.isAdmin = isAdmin
      this.canRequest = canRequest
      this.warnTips = warnTips
      this.sellCountBudget = sellCountBudget
      this.tips = tips
      this.floor = floor
      this.confirm = confirm

      this.courseList = courseList.map((item, index) => {
        item.goodsTitle = item.catName
        item.cover = item.cover?.url
        item.maxSetCount = item.maxSetCount ?? undefined

        if (this.selectedCourse && this.selectedCourse[item.catID]) {
          item.count = this.selectedCourse[item.catID]
        }

        return item
      })
    },
    async submit(item) {
      try {
        if (this.confirm.enable && item.count >= this.confirm.threshold) {
          await this.$confirm(
            this.confirm.message,
            '商品の選択に進む 確認画面',
            {
              cancelButtonText: 'キャンセル',
              confirmButtonText: '確認',
            }
          )
        }
      } catch (error) {
        return
      }

      this.$router.push({
        path: '/odg/request-basic',
        query: {
          courseID: item.catID,
          courseCount: item.count,
          floor: this.floor.id,
        },
      })
    },
  },
}
