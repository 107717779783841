//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyDetailPage',

  layout: 'page',

  data() {
    return {
      id: null,
      companyInfo: {},
      ypRegisterInfo: null,
      owners: [],
      managers: [],
      planInfo: {},
      floor: null,
      floors: [],
      termsOfService: [],
    }
  },

  computed: {
    planMail() {
      return `mailto:cs@officedeyasai.jp?subject=${this.companyInfo.name} ${this.companyInfo.floor}様  プラン変更依頼`
    },
  },

  mounted() {
    this.fetchDetail()
  },

  methods: {
    async fetchDetail() {
      const res = await this.$axios.$get('/company/detail', {
        params: {
          id: this.floor,
        },
      })

      if (!res.data) {
        return
      }

      const {
        id,
        companyInfo,
        ypRegisterInfo,
        owners,
        managers,
        planInfo,
        floors,
        termsOfService,
      } = res.data

      this.id = id
      this.floors = floors
      this.floor = floors.find((item) => item.id === id)?.id
      this.companyInfo = companyInfo
      this.ypRegisterInfo = ypRegisterInfo
      this.owners = owners
      this.managers = managers
      this.planInfo = planInfo
      this.termsOfService = termsOfService
    },
  },
}
