//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/plugins/filters'

export default {
  name: 'InvoicePage',

  layout: 'page',

  data() {
    return {
      listLoading: false,
      date: new Date(),
      dateMax: new Date(),
      floor: null,
      floors: null,
      list: [],
    }
  },

  computed: {
    year() {
      return formatDate(this.date, 'yyyy')
    },
  },

  mounted() {
    this.fetchList()
  },

  methods: {
    async fetchList(page) {
      this.listLoading = true

      const res = await this.$axios.$get('/invoice/list', {
        params: {
          year: this.year,
          cid: this.floor,
        },
      })

      this.listLoading = false

      const { list, floors } = res.data

      if (floors) {
        this.floors = floors
        this.floor = floors.find((floor) => floor.id === this.floor)
          ? this.floor
          : floors[0].id
      }

      this.list = list.map((item) => {
        item.floor = floors?.find((floor) => floor.id === item.cid)?.name
        item.pdfLink = `/invoice/download?cid=${item.cid}&month=${item.month}`

        return item
      })
    },
  },
}
