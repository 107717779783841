//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {}
  },

  methods: {
    async userLogout() {
      await this.$auth.logout()

      // Logout reset
      this.$store.commit('updateDownloadList', [])

      this.$toast({
        message: 'ログアウトの成功',
      })
    },
    async ssoLinkTo() {
      const res = await this.$axios.$post('/zendesk/sso')

      if (res.url) {
        location.href = res.url
      }
    },
  },
}
